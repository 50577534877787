<template>
    <section id="content">
        <div class="content-wrap pt-0">

            <!-- Client Carousel
            ============================================= -->
            <div class="carouselsection bg-transparent m-0 border-bottom border-top">
                <div class="container">
                    <VueSlickCarousel v-bind="settings">
                        <div><img src="/assets/images/clients/aqqire.png"></div>
                        <div><img src="/assets/images/clients/manscaped.jpeg"></div>
                        <div><img src="/assets/images/clients/cvent.jpeg"></div>
                        <div><img src="/assets/images/clients/iscribe.jpeg"></div>
                        <div><img src="/assets/images/clients/copper.png"></div>
                        <div><img src="/assets/images/clients/brightwheel.png"></div>
                        <div><img src="/assets/images/clients/upcity.png"></div>
                        <div><img src="/assets/images/clients/uservoice.png"></div>
                        <div><img src="/assets/images/clients/affinity.jpg"></div>
                    </VueSlickCarousel>
                </div>
            </div>

            <!-- Features
            ============================================= -->
            <div class="section custombg">
                <div class="container">
                    <div class="row col-mb-30 align-content-stretch">

                        <div class="col-lg-4 col-md-6 d-flex">
                            <router-link to="/customer-success" class="card h-shadow h-translate-y-sm all-ts customflex">
                                <div class="card-body p-5">
                                    <div class="feature-box flex-column">
                                        <div class="fbox-image mb-5 text-center">
                                            <img height="150" src="/assets/images/services/cards/customersupport.jpg" alt="Feature Icon">
                                        </div>
                                        <div class="fbox-content">
                                            <h3 class="nott ls0 text-larger">Customer Success</h3>
                                            <!-- <p class="text-smaller">Set your business apart with world-class customer support services. eFlex amplifies your core business processes through a KPI-driven philosophy and bespoke teams that are a perfect match for your business goals.</p> -->
                                        </div>
                                        <ul class="iconlist ml-3 mt-4 mb-0">
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Task Processing and Management</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Chat/In-App Messaging Support</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Email Support</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Phone Support </li>
                                        </ul>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex">
                            <router-link to="/technical-support" class="card h-shadow h-translate-y-sm all-ts customflex">
                                <div class="card-body p-5">
                                    <div class="feature-box flex-column">
                                        <div class="fbox-image mb-5 text-center">
                                            <img height="150" src="/assets/images/services/cards/softwaredevelopment.jpg" alt="Feature Icon">
                                        </div>
                                        <div class="fbox-content">
                                            <h3 class="nott ls0 text-larger">Technical Support</h3>
                                            <!-- <p class="text-smaller">eFlex is your offshore partner providing experienced, proactive, flexible, and committed talent that ensures all technical aspects of your business are functioning optimally. Our agents have detailed knowledge of your service to guarantee first-rate assistance for any technical or UX issues.</p> -->
                                        </div>
                                        <ul class="iconlist ml-3 mt-4 mb-0">
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Technical Expertise</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> IT / Computer Science Background</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> API Experience</li>
                                        </ul>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex">
                            <router-link to="/customer-experience" class="card h-shadow h-translate-y-sm all-ts customflex">
                                <div class="card-body p-5">
                                    <div class="feature-box flex-column">
                                        <div class="fbox-image mb-5 text-center">
                                            <img height="150" src="/assets/images/services/cards/customersuccess.jpg" alt="Feature Icon">
                                        </div>
                                        <div class="fbox-content">
                                            <h3 class="nott ls0 text-larger">Customer Experience</h3>
                                            <!-- <p class="text-smaller">Partnerships are in the eFlex DNA. Our customer success specialists are passionate go-getters who actively engage with your clients, so they achieve their desired outcomes through your growing business. We instill customer success strategies that strengthen long-term business relationships.</p> -->
                                        </div>
                                        <ul class="iconlist ml-3 mt-4 mb-0">
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Planning and Onboarding</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Upsell / Cross-sell</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Customer Health Metrics</li>
                                        </ul>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex">
                            <router-link to="/real-estate" class="card h-shadow h-translate-y-sm all-ts customflex">
                                <div class="card-body p-5">
                                    <div class="feature-box flex-column">
                                        <div class="fbox-image mb-5 text-center">
                                            <img height="150" src="/assets/images/services/cards/realestate.jpg" alt="Feature Icon">
                                        </div>
                                        <div class="fbox-content">
                                            <h3 class="nott ls0 text-larger">Real Estate</h3>
                                            <!-- <p class="text-smaller">As your strategic partner, eFlex knows the ins and outs of real estate. We act as your right hand in running marketing, research, scheduling, and back-office work so you can focus on closing that next deal.</p> -->
                                        </div>
                                        <ul class="iconlist ml-3 mt-4 mb-0">
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Multi-channel listings update</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Database and property management</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Marketing material creation</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Follow up and appointment setting</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Data mining</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Reporting</li>
                                        </ul>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex">
                            <router-link to="/marketing-assistance" class="card h-shadow h-translate-y-sm all-ts customflex">
                                <div class="card-body p-5">
                                    <div class="feature-box flex-column">
                                        <div class="fbox-image mb-5 text-center">
                                            <img height="150" src="/assets/images/services/cards/marketing.jpg" alt="Feature Icon">
                                        </div>
                                        <div class="fbox-content">
                                            <h3 class="nott ls0 text-larger">Marketing Assistance</h3>
                                            <!-- <p class="text-smaller">eFlex can focus on marketing tasks such as research, monitoring social media, and managing campaigns amongst other things, so that your marketing team is empowered to focus on core tasks such as strategy, brand management, and content creation.</p> -->
                                        </div>
                                        <ul class="iconlist ml-3 mt-4 mb-0">
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Target Market Research</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Social Media Monitoring</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Email Campaign Creation and Management</li>
                                        </ul>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex">
                            <router-link to="/marketing-assistance" class="card h-shadow h-translate-y-sm all-ts customflex">
                                <div class="card-body p-5">
                                    <div class="feature-box flex-column">
                                        <div class="fbox-image mb-5 text-center">
                                            <img height="150" src="/assets/images/services/cards/sales.jpg" alt="Feature Icon">
                                        </div>
                                        <div class="fbox-content">
                                            <h3 class="nott ls0 text-larger">Sales Development</h3>
                                            <!-- <p class="text-smaller">eFlex has experience with building sales teams complete with prospectors to create lead lists and SDRs and BDRs to do outreach and set appointments or demos. eFlex empowers your sales team to close more deals. We can help fill your pipeline.</p> -->
                                        </div>
                                        <ul class="iconlist ml-3 mt-4 mb-0">
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Prospecting</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Email Outreach</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Outbound Calls</li>
                                        </ul>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex">
                            <router-link to="/marketing-assistance" class="card h-shadow h-translate-y-sm all-ts customflex">
                                <div class="card-body p-5">
                                    <div class="feature-box flex-column">
                                        <div class="fbox-image mb-5 text-center">
                                            <img height="150" src="/assets/images/services/cards/HR.jpg" alt="Feature Icon">
                                        </div>
                                        <div class="fbox-content">
                                            <h3 class="nott ls0 text-larger">HR Assistance</h3>
                                            <!-- <p class="text-smaller">Reliable and experienced professionals will contribute to a productive work environment by implementing and monitoring your company’s employee development processes, programs and policies. As we integrate with your organization, we provide HR support in recruiting, hiring, training, and staff relations to improve workplace efficiency.</p> -->
                                        </div>
                                        <ul class="iconlist ml-3 mt-4 mb-0">
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Recruiting and Hiring</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Employee Development Programs</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Resource Planning Assistance</li>
                                        </ul>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex">
                            <router-link to="/marketing-assistance" class="card h-shadow h-translate-y-sm all-ts customflex">
                                <div class="card-body p-5">
                                    <div class="feature-box flex-column">
                                        <div class="fbox-image mb-5 text-center">
                                            <img height="150" src="/assets/images/services/cards/Billing.jpg" alt="Feature Icon">
                                        </div>
                                        <div class="fbox-content">
                                            <h3 class="nott ls0 text-larger">Billing and Accounting Assistance</h3>
                                            <!-- <p class="text-smaller">With a partnership approach to your business, eFlex provides exceptional billing and accounting services and unwavering trust.</p> -->
                                        </div>
                                        <ul class="iconlist ml-3 mt-4 mb-0">
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Billing and Invoicing</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Expense Reporting</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Account Maintenance</li>
                                        </ul>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-lg-4 col-md-6 d-flex">
                            <router-link to="/operational-assistance" class="card h-shadow h-translate-y-sm all-ts customflex">
                                <div class="card-body p-5">
                                    <div class="feature-box flex-column">
                                        <div class="fbox-image mb-5 text-center">
                                            <img height="150" src="/assets/images/services/cards/operations.jpg" alt="Feature Icon">
                                        </div>
                                        <div class="fbox-content">
                                            <h3 class="nott ls0 text-larger">Operational Assistance</h3>
                                            <!-- <p class="text-smaller">Crafted with the intent of building your very own offshore team, eFlex empowers you to focus on day-to-day functions. We collaborate to design and execute strategies and processes that minimize pain points and increase growth potential.</p> -->
                                        </div>
                                        <ul class="iconlist ml-3 mt-4 mb-0">
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Assistance with Operational Tasks</li>
                                            <li class="mb-2 text-muted"><i class="icon-check text-smaller color"></i> Implementation of Processes and Procedures</li>
                                            <li class="text-muted"><i class="icon-check text-smaller color"></i> Tracking and Reporting</li>
                                        </ul>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                    </div>
                </div>
            </div>

            <!-- Testimonials
            ============================================= -->
            

            <!-- Form Section
            ============================================= -->
            <Contact/>

        </div>
        <div id="gotoTop" class="icon-angle-up"></div>
    </section><!-- #content end -->
</template>

<script>
import Contact from '@/components/ContactForm'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'HomeContent',
    components: {
        Contact,
        VueSlickCarousel
    },
    data() {
        return {
            settings: {
                "arrows": true,
                "dots": true,
                "infinite": true,
                "slidesToShow": 3,
                "draggable": false,
                "autoplay": true,
                "autoplaySpeed": 1500,
                "lazyload": true,
                "pauseOnHover": false,
                

            }
        }
    }
}
</script>

<style scoped>
.carouselsection {
	position: relative;
	width: 100%;
	margin: 60px 0;
	padding: 60px 0 0 0;
	background-color: #F9F9F9;
	overflow: hidden;
}
.clients {
    display: table;
    width: 100%;
}
.clients li {
    display: table-cell;
}
.custombg {
    margin: 0!important;
}
.customflex {
    width:250em;
}
</style>