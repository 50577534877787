import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
// import NotFound from  '../views/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: () => import("../views/FAQ.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/terms",
    name: "Terms of Use",
    component: () => import("../views/Policies/Terms.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/Policies/Privacy.vue"),
  },
  {
    path: "/build-your-offshore-team",
    name: "eFlexAdvertising",
    component: () => import("../views/eFlexAdvertising.vue"),
  },
  {
    path: "/book-a-call",
    name: "CallBooking",
    component: () => import("../views/CallBooking.vue"),
  },
  {
    path: "/virtual-assistant",
    name: "VirtualAssistant",
    component: () => import("../views/VirtualAssistant.vue"),
  },
  {
    path: "/affinity-success",
    name: "SuccessStoryLanding",
    component: () => import("../views/SuccessStoryLanding.vue"),
  },
  {
    path: "/aqqire-success",
    name: "SuccessStoryLanding",
    component: () => import("../views/SuccessStoryAQQIRE.vue"),
  },
  {
    path: "/book-a-call/calendly",
    name: "CallBookingCalendly",
    component: () => import("../components/CalendlyRedirect.vue"),
  },
  {
    path: "/book-a-call/calendly2",
    name: "CallBookingCalendly2",
    component: () => import("../components/CalendlyRedirect2.vue"),
  },
  {
    path: "/affinity-success/calendly",
    name: "SuccessStoryLandingCalendly",
    component: () => import("../components/CalendlyRedirect.vue"),
  },
  {
    path: "/aqqire-success/calendly",
    name: "SuccessStoryLandingCalendly",
    component: () => import("../components/CalendlyRedirect.vue"),
  },

  // SERVICES

  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services/Services.vue"),
  },
  {
    path: "/real-estate",
    name: "realestate",
    component: () => import("../views/Services/RealEstate.vue"),
  },
  {
    path: "/customer-experience",
    name: "CustomerExperience",
    component: () => import("../views/Services/CustomerEXP.vue"),
  },
  {
    path: "/customer-success",
    name: "CustomerSuccess",
    component: () => import("../views/Services/CustomerSuccess.vue"),
  },
  {
    path: "/technical-support",
    name: "TechnicalSupport",
    component: () => import("../views/Services/TechnicalSupport.vue"),
  },
  {
    path: "/marketing-assistance",
    name: "MarketingAssistance",
    component: () => import("../views/Services/Marketing.vue"),
  },
  {
    path: "/sales-development",
    name: "SalesDevelopment",
    component: () => import("../views/Services/Sales.vue"),
  },
  {
    path: "/billing-and-accounting",
    name: "BillingAndAccountingSuccess",
    component: () => import("../views/Services/BA.vue"),
  },
  {
    path: "/HR",
    name: "HR",
    component: () => import("../views/Services/HR.vue"),
  },
  {
    path: "/operational-assistance",
    name: "OperationalAssistance",
    component: () => import("../views/Services/OperationalAssistance.vue"),
  },
  {
    path: "/shared",
    name: "shared",
    component: () => import("../views/Pricing/Shared.vue"),
  },
  {
    path: "/dedicated",
    name: "dedicated",
    component: () => import("../views/Pricing/Dedicated.vue"),
  },
  {
    path: "/custom",
    name: "custom",
    component: () => import("../views/Pricing/Custom.vue"),
  },

  // BLOG

  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/Blog.vue"),
  },
  {
    path: "/blogs",
    redirect: "/blog",
  },
  {
    path: "/blog/essential-guide-to-business-process-outsourcing",
    name: "BPO",
    component: () => import("../views/Blogs/BPO.vue"),
  },
  {
    path: "/blog/reasons-why-offshore-outsourcing-is-a-smart-move",
    name: "outsourcing",
    component: () => import("../views/Blogs/Outsourcing.vue"),
  },
  {
    path: "/blog/what-is-customer-centric-approach",
    name: "CCA",
    component: () => import("../views/Blogs/CCA.vue"),
  },
  {
    path: "/blog/how-real-estate-vas-grow-your-business",
    name: "realestateva",
    component: () => import("../views/Blogs/RealEstate.vue"),
  },
  {
    path: "/blog/skills-business-process-outsourcing-companies-need",
    name: "KeySkills",
    component: () => import("../views/Blogs/KeySkills.vue"),
  },
  {
    path: "/blog/why-outsource-property-management-services",
    name: "outsourcePMS",
    component: () => import("../views/Blogs/OutsourcePMS.vue"),
  },
  {
    path: "/blog/tasks-to-delegate-to-property-management-virtual-assistant",
    name: "20Tasks",
    component: () => import("../views/Blogs/20Tasks.vue"),
  },
  {
    path: "/blog/customer-service-vs-customer-success",
    name: "ServiceXSuccess",
    component: () => import("../views/Blogs/ServiceXSuccess.vue"),
  },
  {
    path: "/blog/property-management-virtual-assistant-benefits",
    name: "PropertyVA",
    component: () => import("../views/Blogs/PropertyVA.vue"),
  },
  {
    path: "/blog/virtual-assistants-and-return-on-investment",
    name: "VA&ReturnOnInvestment",
    component: () => import("../views/Blogs/VAnROI.vue"),
  },
  {
    path: "/blog/reasons-for-outsourcing-Philippines",
    name: "OutsourcingPH",
    component: () => import("../views/Blogs/OutsourcingPH.vue"),
  },
  {
    path: "/blog/the-good-time-to-outsource",
    name: "OutsourceWhen",
    component: () => import("../views/Blogs/OutsourceWhen.vue"),
  },
  {
    path: "/blog/blending-in-house-and-outsourced-sales-development",
    name: "HouseBlending",
    component: () => import("../views/Blogs/HouseBlending.vue"),
  },
  {
    path: "/blog/what-you-need-to-know-about-outsourcing-for-startups",
    name: "OutsourceStart",
    component: () => import("../views/Blogs/OutsourceStart.vue"),
  },
  {
    path: "/blog/cost-savings-when-hiring-a-va",
    name: "VACostSavings",
    component: () => import("../views/Blogs/VACostSavings.vue"),
  },
  {
    path: "/blog/service-level-agreements-for-outsourcing-services",
    name: "SLA",
    component: () => import("../views/Blogs/SLA.vue"),
  },
  {
    path: "/blog/scheduling-extended-coverage",
    name: "Scheduling",
    component: () => import("../views/Blogs/Scheduling.vue"),
  },
  {
    path: "/blog/debunking-stigma-around-outsourced-sales",
    name: "SalesTeamsStigma",
    component: () => import("../views/Blogs/SalesTeamsStigma.vue"),
  },
  {
    path: "/blog/google-analytics-4-vs-universal-analytics-which-is-better",
    name: "GA",
    component: () => import("../views/Blogs/GA.vue"),
  },
  {
    path: "/blog/highly-skilled-talent-in-the-philippines",
    name: "PHTalent",
    component: () => import("../views/Blogs/PHTalent.vue"),
  },
  {
    path: "/blog/Strategic-Benefits-of-Multisourcing",
    name: "Multisourcing",
    component: () => import("../views/Blogs/Multisourcing.vue"),
  },
  {
    path: "/blog/Strategic-Recruitment-to-Attract-Top-Talent-for-Business-Success",
    name: "Recruitment",
    component: () => import("../views/Blogs/Recruitment.vue"),
  },
  {
    path: "/blog/Increase-Customer-Engagement",
    name: "CustomerEngagement",
    component: () => import("../views/Blogs/CustomerEngagement.vue"),
  },
  {
    path: "/blog/virtual-assistants-for-eCommerce-business",
    name: "eCommerceVA",
    component: () => import("../views/Blogs/eCommerceVA.vue"),
  },
  {
    path: "/blog/customized-outsourcing-advantages",
    name: "OutsourcingSolutions",
    component: () => import("../views/Blogs/OutsourcingSolutions.vue"),
  },
  {
    path: "/blog/enhancing-logistics-through-strategic-outsourcing",
    name: "StrategicOutsourcing",
    component: () => import("../views/Blogs/StrategicOutsourcing.vue"),
  },
  {
    path: "/blog/secure-efficient-outsourcing-technology",
    name: "EfficientOutsourcing",
    component: () => import("../views/Blogs/EfficientOutsourcing.vue"),
  },
  {
    path: "/blog/the-new-era-of-outsourcing-in-finance-how-lending-companies-benefit",
    name: "OffshoreOutsourcing",
    component: () => import("../views/Blogs/Offshore.vue"),
  },
  {
    path: "/blog/transforming-funeral-home-operations-through-outsourcing",
    name: "TransformingFuneral",
    component: () => import("../views/Blogs/TransformingFuneral.vue"),
  },
  {
    path: "/blog/voted-most-trusted-bpo-company-and-now-soc-ii-compliant",
    name: "MostTrustedBpo",
    component: () => import("../views/Blogs/MostTrustedBpo.vue"),
  },
  {
    path: "/blog/why-outsourcing-is-the-secret-weapon-for-small-business-owners",
    name: "OutsourcingSecret",
    component: () => import("../views/Blogs/OutsourcingSecretWeapon.vue"),
  },
  {
    path: "/blog/evolution-of-outsourcing-in-hotels-and-hospitality",
    name: "OutSourcingHotel",
    component: () => import("../views/Blogs/OutsourcingHotels.vue"),
  },
  {
    path: "/blog/outsourcing-sustainable-growth-flexibility",
    name: "powerFlexibility",
    component: () => import("../views/Blogs/powerFlexibility.vue"),
  },
  {
    path: "/blog/virtual-assistants-pet-concierge-businesses",
    name: "VirtualAssistants",
    component: () => import("../views/Blogs/VirtualAssistants.vue"),
  },
  {
    path: "/blog/outsourcing-strategies-economic-downturn",
    name: "EconomicDownturn",
    component: () => import("../views/Blogs/EconomicDownturns.vue"),
  },
  {
    path: "/blog/bridging-skills-gap-virtual-assistants",
    name: "BridgingVA",
    component: () => import("../views/Blogs/BridgingVa.vue"),
  },
  {
    path: "/blog/unlocking-efficiency-the-power-of-outsourcing-complex-tasks",
    name: "UnlockingComplex",
    component: () => import("../views/Blogs/UnlockingComplex.vue"),
  },
  {
    path: "/blog/maximizing-efficiency-with-ai-and-outsourcing",
    name: "AiOutsourcing",
    component: () => import("../views/Blogs/AiOutsourcing.vue"),
  },
  {
    path: "/blog/onboarding-outsourced-teams-what-to-expect-and-how-to-prepare",
    name: "OnboardingOutSourcedTeams",
    component: () => import("../views/Blogs/OnboardingOutsourcedTeams.vue"),
  },
  {
    path: "/blog/strong-outsourcing-relationships-open-communication",
    name: "SecretToStrongOutsourcing",
    component: () => import("../views/Blogs/SecretToStrong.vue"),
  },
  {
    path: "/blog/virtual-team-best-practices-seamless-collaboration",
    name: "VirtualBestPractices",
    component: () => import("../views/Blogs/VirtualBestPractices.vue"),
  },
  {
    path: "/blog/scaling-your-business-with-outsourcing-strategic-guide",
    name: "ScalingBusinessOutsourcing",
    component: () => import("../views/Blogs/ScalingBusinessOutsourcing.vue"),
  },
  {
    path: "/blog/long-term-success-trusting-outsourcing-partners",
    name: "BusinessTrustOutsourcing",
    component: () => import("../views/Blogs/BusinessTrustOutsourcing.vue"),
  },
  {
    path: "/blog/safeguard-data-outsourcing-offshore-teams",
    name: "SafeguardDataOutSourcing",
    component: () => import("../views/Blogs/SafeguardDataOutSourcing.vue"),
  },
  {
    path: "/blog/essential-performance-metrics-outsourcing-success",
    name: "EssentialPerformanceMetrics",
    component: () => import("../views/Blogs/EssentialPerformanceMetrics.vue"),
  },
  {
    path: "/blog/mastering-project-management-offshore-distributed-teams",
    name: "MasteringProjectManagement",
    component: () => import("../views/Blogs/MasteringProjectManagement.vue"),
  },
  {
    path: "/blog/maintaining-company-culture-virtual-workforce",
    name: "MaintainingCompanyCulture",
    component: () => import("../views/Blogs/MaintainingCompanyCulture.vue"),
  },











  // CAREERS
  {
    path: "/career",
    name: "career-redirect",
    redirect: "/careers",
  },
  // {
  //   path: '/careers/real-estate-virtual-assistant',
  //   name: 'RealEstateVirtualAssistant',
  //   component: () => import('../views/Careers/REVA.vue'),
  //   alias: '/careers'
  // },
  {
    path: "/careers/csr-tsr",
    name: "AffinityCSR",
    component: () => import("../views/Careers/AffinityCSR.vue"),
    alias: "/careers",
  },
  {
    path: "/careers/cms-specialist",
    name: "CMSSpecialist",
    component: () => import("../views/Careers/CMSSpec.vue"),
  },
  // {
  //   path: '/careers/graphic-designer',
  //   name: 'GraphicDesigner',
  //   component: () => import('../views/Careers/GD.vue')
  // },
  // {
  //   path: '/careers/backend-dev',
  //   name: 'BackendDev',
  //   component: () => import('../views/Careers/BackendDev.vue')
  // },
  // {
  //   path: '/careers/marketing-coordinator',
  //   name: 'MarketingCoordinator',
  //   component: () => import('../views/Careers/MarketingCoordinator.vue')
  // },
  // {
  //   path: '/careers/data-analyst',
  //   name: 'DataAnalyst',
  //   component: () => import('../views/Careers/DataAnalyst.vue')
  // },
  // {
  //   path: '/careers/finance-agent',
  //   name: 'FinanceAgent',
  //   component: () => import('../views/Careers/FinanceAgent.vue')
  // },
  {
    path: "/careers/QA-Engineer",
    name: "QAE",
    component: () => import("../views/Careers/QAE.vue"),
  },
  // {
  //   path: '/careers/senior-web-dev',
  //   name: 'SeniorWebDev',
  //   component: () => import('../views/Careers/SeniorWebDev.vue')
  // },
  // {
  //   path: '/careers/sourcing-specialist',
  //   name: 'SourcingSpecialist',
  //   component: () => import('../views/Careers/SourcingSpecialist.vue')
  // },
  {
    path: "/careers/product-tsr",
    name: "PTSR",
    component: () => import("../views/Careers/PTSR.vue"),
  },
  {
    path: "/careers/seo-ra",
    name: "SEORA",
    component: () => import("../views/Careers/SEORA.vue"),
  },
  // {
  //   path: '/careers/bilingual-billing-specialist',
  //   name: 'BillingSpecialist',
  //   component: () => import('../views/Careers/Billing.vue')
  // },
  {
    path: "/careers/SDR",
    name: "SDR",
    component: () => import("../views/Careers/SDR.vue"),
  },
  // {
  //   path: '/careers/sales-support-specialist',
  //   name: 'SalesSupport',
  //   component: () => import('../views/Careers/SalesSupport.vue')
  // },
  // {
  //   path: '/careers/data-entry',
  //   name: 'DataEntry',
  //   component: () => import('../views/Careers/DataEntry.vue')
  // },
  // {
  //   path: '/careers/3D-graphic-designer',
  //   name: '3DGD',
  //   component: () => import('../views/Careers/3DGD.vue')
  // },
  {
    path: "/careers/lead-generation-specialist",
    name: "LeadGen",
    component: () => import("../views/Careers/LeadGen.vue"),
  },
  {
    path: "/careers/cold-caller",
    name: "ColdCaller",
    component: () => import("../views/Careers/ColdCaller.vue"),
    alias: "/careers",
  },
  // {
  //   path: '/careers/accounting-manager',
  //   name: 'Accounting',
  //   component: () => import('../views/Careers/Accounting.vue')
  // },
  {
    path: "/careers/senior-accountant",
    name: "SeniorAccountant",
    component: () => import("../views/Careers/SeniorAccountant.vue"),
  },
  {
    path: "/careers/billing-specialist",
    name: "BillingSpecialist",
    component: () => import("../views/Careers/BillingSpec.vue"),
  },
  {
    path: "/careers/amazon-virtual-assistant",
    name: "AmazonVA",
    component: () => import("../views/Careers/AmazonVA.vue"),
  },
  // {
  //   path: '/careers/solutions-engineer',
  //   name: 'SolutionsEngineer',
  //   component: () => import('../views/Careers/SolutionsEngineer.vue')
  // },
  {
    path: "/careers/web-developer",
    name: "FrontEndDev",
    component: () => import("../views/Careers/FrontEndDev.vue"),
  },
  {
    path: "/careers/IT-specialist",
    name: "ITSpecialist",
    component: () => import("../views/Careers/ITSpecialist.vue"),
  },
  // {
  //   path: '/careers/senior-IT-specialist',
  //   name: 'ITSpecialist',
  //   component: () => import('../views/Careers/SrITSpecialist.vue')
  // },
  {
    path: "/careers/IT-security-analyst",
    name: "SecurityAnalyst",
    component: () => import("../views/Careers/SecurityAnalyst.vue"),
  },
  // {
  //   path: '/careers/HR-Manager',
  //   name: 'HRManager',
  //   component: () => import('../views/Careers/SeniorRecruiter.vue')
  // },
  {
    path: "/careers/shopify-developer",
    name: "ShopifyDev",
    component: () => import("../views/Careers/ShopifyDev.vue"),
  },
  // {
  //   path: '/careers/shopify-web-designer',
  //   name: 'ShopifyGD',
  //   component: () => import('../views/Careers/ShopifyGD.vue')
  // },
  // {
  //   path: '/careers/shopify-digital-marketing-specialist',
  //   name: 'ShopifyDigitalMarketingSpecialist',
  //   component: () => import('../views/Careers/ShopifyMarketing.vue')
  // },
  {
    path: "/careers/account-executive",
    name: "AccountExecutive",
    component: () => import("../views/Careers/AccountExecutive.vue"),
  },
  // {
  //   path: '/careers/Sales-VA',
  //   name: 'SalesVA',
  //   component: () => import('../views/Careers/SalesVA.vue')
  // },
  {
    path: "/careers/software-developer",
    name: "SoftwareDev",
    component: () => import("../views/Careers/SoftwareDev.vue"),
  },
  // {
  //   path: '/careers/executive-assistant',
  //   name: 'ExecutiveAssistant',
  //   component: () => import('../views/Careers/ExecutiveAssistant.vue')
  // },
  {
    path: "/careers/content-writer",
    name: "ContentWriter",
    component: () => import("../views/Careers/ContentWriter.vue"),
  },
  {
    path: "/careers/junior-accountant",
    name: "JuniorAccountant",
    component: () => import("../views/Careers/JuniorAccountant.vue"),
  },
  {
    path: "/careers/netsuite-administrator",
    name: "SystemAdmin",
    component: () => import("../views/Careers/SystemAdmin.vue"),
  },
  {
    path: "/careers/data-annotator",
    name: "DataAnnotator",
    component: () => import("../views/Careers/DataAnnotator.vue"),
  },
  {
    path: "/careers/IT-helpdesk",
    name: "ITHelpdesk",
    component: () => import("../views/Careers/ITHelpdesk.vue"),
  },
  {
    path: "/careers/helpdesk-engineer",
    name: "HelpdeskEngineer",
    component: () => import("../views/Careers/HelpdeskEngineer.vue"),
  },
  {
    path: "/careers/general-accountant",
    name: "GeneralAccountant",
    component: () => import("../views/Careers/GeneralAccountant.vue"),
  },
  {
    path: "/careers/software-security-engineer",
    name: "SoftwareSecurityEngineer",
    component: () => import("../views/Careers/SoftwareSecurityEngineer.vue"),
  },
  {
    path: "/careers/solutions-engineer",
    name: "IntegrationEngineer",
    component: () => import("../views/Careers/IntegrationEngineer.vue"),
  },

  // CAREER FORMS
  {
    path: "/careers/csr-tsr/form",
    name: "AffinityCSRTSRForm",
    component: () => import("../views/Careers/Forms/ACSRForm.vue"),
  },
  // {
  //   path: '/careers/backend-dev/form',
  //   name: 'BackendDevForm',
  //   component: () => import('../views/Careers/Forms/BackendDevForm.vue')
  // },
  {
    path: "/careers/cms-specialist/form",
    name: "CSMSpecialistForm",
    component: () => import("../views/Careers/Forms/CMSSpecForm.vue"),
  },
  // {
  //   path: '/careers/data-analyst/form',
  //   name: 'DAForm',
  //   component: () => import('../views/Careers/Forms/DAForm.vue')
  // },
  // {
  //   path: '/careers/graphic-designer/form',
  //   name: 'GDForm',
  //   component: () => import('../views/Careers/Forms/GDForm.vue')
  // },
  // {
  //   path: '/careers/marketing-coordinator/form',
  //   name: 'MarketingForm',
  //   component: () => import('../views/Careers/Forms/MarketingForm.vue')
  // },
  // {
  //   path: '/careers/real-estate-virtual-assistant/form',
  //   name: 'REVAForm',
  //   component: () => import('../views/Careers/Forms/REVAForm.vue')
  // },
  // {
  //   path: '/careers/finance-agent/form',
  //   name: 'FinanceAgentForm',
  //   component: () => import('../views/Careers/Forms/FinanceAgentForm.vue')
  // },
  {
    path: "/careers/QA-Engineer/form",
    name: "QAEForm",
    component: () => import("../views/Careers/Forms/QAEForm.vue"),
  },
  // {
  //   path: '/careers/senior-web-dev/form',
  //   name: 'SeniorWebDevForm',
  //   component: () => import('../views/Careers/Forms/SeniorWebDevForm.vue')
  // },
  // {
  //   path: '/careers/sourcing-specialist/form',
  //   name: 'SourcingSpecialistForm',
  //   component: () => import('../views/Careers/Forms/SourcingSpecialistForm.vue')
  // },
  {
    path: "/careers/product-tsr/form",
    name: "PTSRForm",
    component: () => import("../views/Careers/Forms/PTSRForm.vue"),
  },
  {
    path: "/careers/seo-ra/form",
    name: "SEORAForm",
    component: () => import("../views/Careers/Forms/SEORAForm.vue"),
  },
  // {
  //   path: '/careers/bilingual-billing-specialist/form',
  //   name: 'BillingForm',
  //   component: () => import('../views/Careers/Forms/BillingForm.vue')
  // },
  {
    path: "/careers/SDR/form",
    name: "SDRForm",
    component: () => import("../views/Careers/Forms/SDRForm.vue"),
  },
  // {
  //   path: '/careers/sales-support/form',
  //   name: 'SalesSupportForm',
  //   component: () => import('../views/Careers/Forms/SalesSupportForm.vue')
  // },
  // {
  //   path: '/careers/data-entry/form',
  //   name: 'DataEntryForm',
  //   component: () => import('../views/Careers/Forms/DataEntryForm.vue')
  // },
  // {
  //   path: '/careers/3D-graphic-designer/form',
  //   name: '3DGDForm',
  //   component: () => import('../views/Careers/Forms/3DGDForm.vue')
  // },
  {
    path: "/careers/lead-generation-specialist/form",
    name: "LeadGenForm",
    component: () => import("../views/Careers/Forms/LeadGenForm.vue"),
  },
  {
    path: "/careers/cold-caller/form",
    name: "ColdCallerForm",
    component: () => import("../views/Careers/Forms/ColdCallerForm.vue"),
  },
  // {
  //   path: '/careers/accounting-manager/form',
  //   name: 'AccountingForm',
  //   component: () => import('../views/Careers/Forms/AccountingForm.vue')
  // },
  {
    path: "/careers/senior-accountant/form",
    name: "SeniorAccountantForm",
    component: () => import("../views/Careers/Forms/SeniorAccountantForm.vue"),
  },
  {
    path: "/careers/billing-specialist/form",
    name: "BillingSpecForm",
    component: () => import("../views/Careers/Forms/BillingSpecForm.vue"),
  },
  {
    path: "/careers/amazon-virtual-assistant/form",
    name: "AmazonVAForm",
    component: () => import("../views/Careers/Forms/AmazonVAForm.vue"),
  },
  // {
  //   path: '/careers/solutions-engineer/form',
  //   name: 'SolutionsEngineerForm',
  //   component: () => import('../views/Careers/Forms/SolutionsEngineerForm.vue')
  // },
  {
    path: "/careers/web-developer/form",
    name: "FrontEndDevForm",
    component: () => import("../views/Careers/Forms/FrontEndDevForm.vue"),
  },
  {
    path: "/careers/IT-specialist/form",
    name: "ITSpecForm",
    component: () => import("../views/Careers/Forms/ITSpecForm.vue"),
  },
  // {
  //   path: '/careers/senior-IT-specialist/form',
  //   name: 'ITSpecForm',
  //   component: () => import('../views/Careers/Forms/ITSpecForm.vue')
  // },
  {
    path: "/careers/IT-security-analyst/form",
    name: "SecurityAnalystForm",
    component: () => import("../views/Careers/Forms/SecurityAnalystForm.vue"),
  },
  // {
  //   path: '/careers/HR-Manager/form',
  //   name: 'HRManagerForm',
  //   component: () => import('../views/Careers/Forms/SeniorRecruiterForm.vue')
  // },
  {
    path: "/careers/shopify-developer/form",
    name: "ShopifyDevForm",
    component: () => import("../views/Careers/Forms/ShopifyDevForm.vue"),
  },
  // {
  //   path: '/careers/shopify-web-designer/form',
  //   name: 'ShopifyGDForm',
  //   component: () => import('../views/Careers/Forms/ShopifyGDForm.vue')
  // },
  // {
  //   path: '/careers/shopify-digital-marketing-specialist/form',
  //   name: 'ShopifyGDForm',
  //   component: () => import('../views/Careers/Forms/ShopifyGDForm.vue')
  // },
  {
    path: "/careers/account-executive/form",
    name: "AccountExecutiveForm",
    component: () => import("../views/Careers/Forms/AccountExecutiveForm.vue"),
  },
  // {
  //   path: '/careers/Sales-VA/form',
  //   name: 'SalesVAForm',
  //   component: () => import('../views/Careers/Forms/SalesVAForm.vue')
  // },
  {
    path: "/careers/software-developer/form",
    name: "SoftwareDevForm",
    component: () => import("../views/Careers/Forms/SoftwareDevForm.vue"),
  },
  // {
  //   path: '/careers/executive-assistant/form',
  //   name: 'ExecutiveAssistantForm',
  //   component: () => import('../views/Careers/Forms/ExecutiveAssistantForm.vue')
  // },
  {
    path: "/careers/content-writer/form",
    name: "ContentWriterForm",
    component: () => import("../views/Careers/Forms/ContentWriterForm.vue"),
  },
  {
    path: "/careers/junior-accountant/form",
    name: "JuniorAccountantForm",
    component: () => import("../views/Careers/Forms/JuniorAccountantForm.vue"),
  },
  {
    path: "/careers/netsuite-administrator/form",
    name: "SystemAdminForm",
    component: () => import("../views/Careers/Forms/SystemAdminForm.vue"),
  },
  {
    path: "/careers/data-annotator/form",
    name: "DataAnnotatorForm",
    component: () => import("../views/Careers/Forms/DataAnnotatorForm.vue"),
  },
  {
    path: "/careers/IT-helpdesk/form",
    name: "ITHelpdeskForm",
    component: () => import("../views/Careers/Forms/ITHelpdeskForm.vue"),
  },
  {
    path: "/careers/helpdesk-engineer/form",
    name: "HelpdeskEngineerForm",
    component: () => import("../views/Careers/Forms/HelpdeskEngineerForm.vue"),
  },
  {
    path: "/careers/general-accountant/form",
    name: "GeneralAccountantForm",
    component: () => import("../views/Careers/Forms/NewForm.vue"),
  },
  {
    path: "/careers/software-security-engineer/form",
    name: "SoftwareSecurityEngineerForm",
    component: () => import("../views/Careers/Forms/NewForm.vue"),
  },
  {
    path: "/careers/solutions-engineer/form",
    name: "IntegrationEngineerForm",
    component: () => import("../views/Careers/Forms/NewForm.vue"),
  },

  //CAREER THANKS
  {
    path: "/careers/csr-tsr/thankyou",
    name: "AffinityCSRTSRThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/backend-dev/thankyou',
  //   name: 'BackendDevThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/cms-specialist/thankyou",
    name: "CSMSpecialistThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/data-analyst/thankyou',
  //   name: 'DAThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  // {
  //   path: '/careers/graphic-designer/thankyou',
  //   name: 'GDThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  // {
  //   path: '/careers/marketing-coordinator/thankyou',
  //   name: 'MarketingThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  // {
  //   path: '/careers/real-estate-virtual-assistant/thankyou',
  //   name: 'REVAThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  // {
  //   path: '/careers/finance-agent/thankyou',
  //   name: 'FinanceAgentThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/QA-Engineer/thankyou",
    name: "QAEThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/senior-web-dev/thankyou',
  //   name: 'SeniorWebDevThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  // {
  //   path: '/careers/sourcing-specialist/thankyou',
  //   name: 'SourcingSpecialistThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/product-tsr/thankyou",
    name: "PTSRThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/seo-ra/thankyou",
    name: "SEORAThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/bilingual-billing-specialist/thankyou',
  //   name: 'BillingThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/SDR/thankyou",
    name: "SDThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/sales-support/thankyou',
  //   name: 'SalesSupportThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  // {
  //   path: '/careers/data-entry/thankyou',
  //   name: 'DataEntryThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  // {
  //   path: '/careers/3D-graphic-designer/thankyou',
  //   name: '3DGDThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/lead-generation-specialist/thankyou",
    name: "LeadGenThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/cold-caller/thankyou",
    name: "ColdCallerThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/accounting-manager/thankyou',
  //   name: 'AccountingManagerThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/senior-accountant/thankyou",
    name: "SeniorAccountantThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/billing-specialist/thankyou",
    name: "BillingSpecThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/solutions-engineer/thankyou',
  //   name: 'SolutionsEngineerThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/web-developer/thankyou",
    name: "FrontEndDevThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/IT-specialist/thankyou',
  //   name: 'ITspecialistThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/IT-security-analyst/thankyou",
    name: "SecurityAnalystThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/HR-Manager/thankyou',
  //   name: 'HRManagerThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/shopify-developer/thankyou",
    name: "ShopifyDevThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/shopify-web-designer/thankyou',
  //   name: 'ShopifyGDThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  // {
  //   path: '/careers/shopify-digital-marketing-specialist/thankyou',
  //   name: 'ShopifyMarketingThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/account-executive/thankyou",
    name: "AccountExecutiveThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/Sales-VA/thankyou',
  //   name: 'SalesVAThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/software-developer/thankyou",
    name: "SoftwareDeveloperThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  // {
  //   path: '/careers/executive-assistant/thankyou',
  //   name: 'ExecutiveAssistantThanks',
  //   component: () => import('../views/Careers/Thanks.vue')
  // },
  {
    path: "/careers/content-writer/thankyou",
    name: "ContentWriterThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/junior-accountant/thankyou",
    name: "JuniorAccountantThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/netsuite-administrator/thankyou",
    name: "SystemAdminThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/data-annotator/thankyou",
    name: "DataAnnotatorThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/IT-helpdesk/thankyou",
    name: "ITHelpdeskThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/helpdesk-engineer/thankyou",
    name: "HelpdeskEngineerThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/general-accountant/thankyou",
    name: "GeneralAccountantThanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/software-security-engineer/thankyou",
    name: "SoftwareSecurityEngineerhanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/careers/solutions-engineer/thankyou",
    name: "IntegrationEngineerhanks",
    component: () => import("../views/Careers/Thanks.vue"),
  },
  {
    path: "/meet-the-team",
    name: "MeetTheTeam",
    component: () => import("../views/MeetTheTeam.vue"),
  },

  // Pricing thanks
  {
    path: "/thankyou",
    name: "thanks",
    component: () => import("../views/Pricing/Thanks.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (to.path === "/thankyou" && from.path !== '/custom' || from.path !== '/shared' || from.path !== '/dedicated') {
    //     this.$route.push('/')
    //   } else {
    //     next('/thankyou')
    //   }
    // }
  },
  // {
  //   path:'/holiday',
  //   name: 'Holiday',
  //   component: () => import('../views/Holiday.vue')
  // },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
    //component: NotFound
  },
  {
    path: "*",
    redirect: "/404",
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    // No route matched, trigger 404
    next("/404")
    // Send a custom HTTP status code to Nginx
    router.app.$once("hook:mounted", () => {
      document.dispatchEvent(new CustomEvent("vuejs-404", { detail: true }))
    })
  } else {
    // Route matched, proceed normally
    next()
  }
})

export default router
