<template>
    <header id="header" class="sticky-header transparent-header floating-header header-size-md transparent">
        <div id="header-wrap">
            <div class="container">
                <div class="header-row">

                    <!-- Logo
                    ============================================= -->
                    <div id="logo">
                        <router-link to="/"><a data-dark-logo="/assets/images/logo-dark.png"><img src="/assets/images/LogoFinal3.png" alt="eFlex Logo" class="logoimg" style="max-width: 100%; max-height: 100%; width: auto; margin-top: 10px; margin-bottom: 10px;"></a></router-link>
                        <!-- <router-link to="/"><a class="retina-logo" data-dark-logo="/assets/images/logo-dark.png"><img src="/assets/images/LogoFinal3.png" alt="eFlex Logo" class="logoimg" style="max-width: 100%; max-height: 100%; width: auto;"></a></router-link> -->
                    </div><!-- #logo end -->

                    <div class="header-misc">
                        <router-link to="/contact"><a class="button button-rounded ml-3 d-none d-sm-block">Get Started</a></router-link>
                    </div>

                    <div id="primary-menu-trigger">
                        <svg class="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
                    </div>

                    <!-- Primary Navigation
                    ============================================= -->
                    <nav class="primary-menu with-arrows">
                        <ul class="menu-container">
                            <router-link to="/"><li class="menu-item"><a class="menu-link"><div>Home</div></a></li></router-link>
                            <router-link to="/About"><li class="menu-item"><a class="menu-link"><div>Who We Are</div></a></li></router-link>
                            <li class="menu-item mega-menu mega-menu-small"><div class="menu-link"><div>Services</div></div>
                                <div class="mega-menu-content mega-menu-style-2 px-0">
                                    <div class="container">
                                        <div class="row">
                                            <router-link to="/customer-success" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Customer Success</h3>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <router-link to="/technical-support" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Technical Support</h3>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <router-link to="/customer-experience" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Customer Experience</h3>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <router-link to="/real-estate" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                    <div class="feature-box">
                                                        <div class="fbox-content">
                                                            <h3 class="nott ls0">Real Estate Success</h3>
                                                        </div>
                                                    </div>
                                            </router-link>
                                            <router-link to="/marketing-assistance" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Marketing Assistance</h3>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <router-link to="/sales-development" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Sales Development</h3>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <router-link to="/billing-and-accounting" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Billing and Accounting</h3>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <router-link to="/HR" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">HR Success</h3>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <router-link to="/operational-assistance" class="mega-menu-column sub-menu-container col-lg-4 border-bottom h-bg-light py-4">
                                                <div class="feature-box">
                                                    <div class="fbox-content">
                                                        <h3 class="nott ls0">Operational Assistance</h3>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <div class="mega-menu-column sub-menu-container col-12 p-0">
                                                <router-link to="/services"><a class="button button-rounded button-large nott ls0 btn-block text-center m-0 rounded-0 py-3git add">View All <i class="icon-line-arrow-right"></i></a></router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <router-link to="/FAQ"><li class="menu-item"><a class="menu-link"><div>FAQs</div></a></li></router-link>
                        </ul>
                    </nav>
                    <!-- #primary-menu end -->

                </div>
            </div>
        </div>
        <!-- <div class="header-wrap-clone"></div> -->
    </header><!-- #header end -->
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.standard-logo {
    padding: 10px;
}
.logoimg {
    width: 110%;
    height: 110%;
}
</style>