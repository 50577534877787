<template>
    <!-- Form Section
    ============================================= -->
    <div class="section mb-3" style="background: url('/assets/images/contact.jpg') no-repeat center center; background-size: cover; padding: 100px 0;">
        <div class="container">
            <div class="row justify-content-between align-items-center">

            <div class="col-md-4">
                <div class="heading-block border-bottom-0 bottommargin-sm">
                    <div class="badge badge-pill badge-default">Let's Build</div>
                    <h3 class="nott ls0">Build Your Dream Team Today</h3>
                </div>
                <p>
                    We'll build a team with the right expertise and experience to help you achieve your objectives! Let's get started on getting your organization to the next level.
                </p>
            </div>

                <div class="col-lg-3 col-md-4">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <div class="form-widget">
                                <div class="form-result"></div>
                                <form class="row mb-0" id="template-contactform" name="template-contactform" @submit="sendEmail" novalidate="true">
                                    <div class="col-12 form-group mb-3">
                                        <label for="template-contactform-name">Name:*</label>
                                        <input type="text" id="template-contactform-name" name="template-contactform-name" class="form-control input-sm required" v-model="name">
                                    </div>
                                    <div class="col-12 form-group mb-3">
                                        <label for="template-contactform-email">Email Address:*</label>
                                        <input type="email" id="template-contactform-email" name="template-contactform-email" class="form-control input-sm required" v-model="email">
                                    </div>
                                    <div class="col-12 form-group mb-4">
                                        <label for="template-contactform-website">Provide a description of your challenges:*</label>
                                        <textarea id="template-contactform-website" name="template-contactform-website" class="form-control input-sm required challenges" v-model="message"/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                            <p v-if="errors.length">
                                                <b>Please fill in the missing details: </b>
                                                <ul>
                                                    <li v-for="error in errors" :key="error" class="error">{{ error }}</li>
                                                </ul>
                                            </p>
                                        </div>
                                    <div class="col-12 form-group mb-0">
                                        <button class="button button-rounded btn-block nott ls0 m-0" type="submit" id="template-contactform-submit" name="template-contactform-submit" value="submit">Get Started</button>
                                    </div>

                                    <input type="hidden" name="prefix" value="template-contactform-">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mt-5 mt-md-0 center">
                    
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import emailjs from 'emailjs-com'
import{ init } from 'emailjs-com'
init("user_omZRSVBH1M8JlNRLhJMnP");
export default {
    data() {
        return {
            errors: [],
            name:'',
            email:'',
            message:'',
            to_email:'hello@eflexervices.com'
        }
    },
    methods : {
        sendEmail: function(e) {
            if(this.name && this.email && this.message) {
                const templateParams = {
                    from_name: this.name,
                    from_email: this.email,
                    message: this.message,
                    to_email: this.to_email,
                }
                emailjs.send('service_d8057wo', 'template_9vsmlwq', templateParams,
                'user_omZRSVBH1M8JlNRLhJMnP')
                this.$router.push('/thankyou')
            }
            this.errors = []
            if(!this.name) {
				this.errors.push('Name Required')
			}
			if(!this.email) {
				this.errors.push('Email Required')
			} else if(!this.validEmail(this.email)) {
				this.errors.push('Valid Email Required')
			}
			if(!this.message) {
				this.errors.push("What message you want to send to us")
			}
			e.preventDefault()
        },
        validEmail: function(email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email)
		}
    }
}
</script>

<style scoped>
.challenges {
    resize: vertical;
    overflow: auto;
    height: 110px;
    max-height: 110px;
}

</style>