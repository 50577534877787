<template>
  <div id="wrapper" class="clearfix">
    <Header/>
      <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/HeaderTopFooter/Header'
import Footer from './components/HeaderTopFooter/Footer'

export default {
  name: 'wrapper',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
  @import './assets/css/components/bs-switches.css';
  @import './assets/css/animate.css';
  @import './assets/css/bootstrap.css';
  @import './assets/css/custom.css';
  @import './assets/css/dark.css';
  @import './assets/css/font-icons.css';
  @import './assets/css/fonts.css';
  @import './assets/css/magnific-popup.css';
  @import './assets/css/seo.css';
  @import './assets/style.css';
</style>
