<template>
  <div id="wrapper" class="home clearfix">
    <Slider/>
    <Content/>
  </div>
</template>

<script>
import Slider from '../components/Home/Slider'
import Content from '../components/Home/HomeContent'

export default {
  name: 'Home',
  components: {
    Slider,
    Content
  },
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>
